<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="healthRifhts">
      <el-form
        :inline="true"
        :model="dataForm"
        @keyup.enter.native="getDataList()"
      >
        <el-form-item>
          <el-input
            v-model="dataForm.packageName"
            :placeholder="$t('package.packageName')"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="dataForm.appCode"
            placeholder="合作方"
            label="appDesc"
            :clearable="true"
            style="width: 100%"
          >
            <el-option
              v-for="item in listByScope"
              :key="item.appCode"
              :label="item.appDesc"
              :value="item.appCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()">{{ $t('query') }}</el-button>
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="$hasPermission('sys:package:add')"
            type="primary"
            @click="addOrUpdateHandle()"
            >{{ $t('add') }}</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="$hasPermission('sys:package:delete')"
            type="danger"
            @click="deleteHandle()"
            >{{ $t('deleteBatch') }}</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        v-loading="dataListLoading"
        :data="dataList"
        border
        @selection-change="dataListSelectionChangeHandle"
        @sort-change="dataListSortChangeHandle"
        style="width: 100%"
      >
        <el-table-column
          type="selection"
          header-align="center"
          align="center"
          width="50"
        ></el-table-column>
        <el-table-column
          prop="id"
          :label="$t('rightsCard.packageId')"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="packageCode"
          :label="$t('package.packageCode')"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="packageName"
          :label="$t('rightsCard.packageName')"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="appCode"
          label="合作方"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productId"
          :label="$t('package.productId')"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ computedProductName(scope.row.productId) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('package.status')"
          header-align="center"
          align="center"
          width="180"
          :filters="statusArray"
          :filter-method="filterStatus"
          :filter-multiple="false"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">禁用</span>
            <span v-else>正常</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createDate"
          :label="$t('package.createDate')"
          sortable="custom"
          header-align="center"
          align="center"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="creatorName"
          :label="$t('rightsCard.creator')"
          header-align="center"
          align="center"
          width="180"
        ></el-table-column>
        <el-table-column
          :label="$t('handle')"
          fixed="right"
          header-align="center"
          align="center"
          width="250"
        >
          <template slot-scope="scope">
            <el-button
              v-if="$hasPermission('sys:package:update')"
              type="text"
              size="small"
              @click="addOrUpdateHandle(scope.row.id)"
              >{{ $t('update') }}</el-button
            >
            <el-button
              v-if="$hasPermission('sys:package:delete')"
              type="text"
              size="small"
              @click="deleteHandle(scope.row.id)"
              >{{ $t('delete') }}</el-button
            >
            <!-- <el-button
              v-if="$hasPermission('sys:package:rightsCard')"
              type="text"
              size="small"
              @click="deleteHandle(scope.row.id)"
              >{{ $t('package.rightsCard') }}</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle"
      >
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </el-card>
</template>

<script>
import AddOrUpdate from './rightsPackage-add-or-update';
import mixinViewModule from '@/mixins/view-module';
export default {
  mixins: [mixinViewModule],
  data() {
    return {
      listByScope: [],
      mixinViewModuleOptions: {
        getDataListURL: '/health/package/page',
        getDataListIsPage: true,
        deleteURL: '/health/package/delete',
        deleteIsBatch: true,
        activatedIsNeed: true,
      },
      dataForm: {
        packageName: '',
      },
      statusArray: [
        {
          value: 0,
          text: '禁用',
        },
        {
          value: 1,
          text: '正常',
        },
      ],
      productList: [],
    };
  },
  created() {
    this.getProductList();
    this.getListByScope();
  },
  methods: {
    getListByScope() {
      this.$http
        .get('/sysapp/listByScope')
        .then((res) => {
          console.log(res, 'listByScope');
          if (res.data.code === 0) {
            this.listByScope = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateRightsPackage(val) {
      this.$router.push({
        meta: { title: '修改权益包' },
        path: '/addRightsPackage',
        query: {
          id: val,
        },
      });
    },
    addRightsPackage() {
      this.$router.push({
        meta: { title: '修改权益包' },
        path: '/addRightsPackage',
      });
    },
    computedProductName(val) {
      let name = '';
      if (this.productList.length > 0 && val) {
        let productItem = this.productList.find((item) => {
          return item.id == val;
        });
        name = productItem && productItem.name;
      }
      return name;
    },
    filterStatus(value, row) {
      return row.status == value;
    },
    getProductList() {
      this.$http
        .get('/product/external/list')
        .then((res) => {
          console.log(res);
          if (res.data.code === 0) {
            this.productList = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  components: {
    AddOrUpdate,
  },
};
</script>
